import React, { useRef } from 'react';
import ReactToPrint from 'react-to-print';
import { Box, Button, Card, Flex, Heading, Image, Text } from 'rebass';

import Barcode from '../Barcode';
import { currency, datetime } from '../../utils';
import IconPrint from '../../icons/icon-print.svg';

const BOX_SIZES = {
  no_2: "2",
  no_5: "5",
  no_10: "10",
  no_15: "15",
}

const BoxPrintItem = ({
    box: {
        _id,
        _ts,
        size,
        createdAt,
        shippingCost,
        recipient: { fullname, address },
      },
})=>{
    return(
      <div style={{padding:"10px 0px"}}>
        <Card bg="blues.veryLight" borderRadius="10px" mb="18px">
        <div style={{ 
          background: "#ECEDF0",
          display:"flex",
          flexDirection:"row"
          }}>
          <div style={{padding:"10px", borderRadius:"10px 0 0 10px", background:"#F4D448" }} >
            <Heading
              mx="18px"
              fontSize={["11px"]}
              lineHeight="1.85"
              letterSpacing="0.7px"
              color="marine"
              textAlign="center"
            >
              BOX ID:
            </Heading>
            <Heading
              mx="18px"
              fontSize={["11px"]}
              lineHeight="1.85"
              letterSpacing="0.7px"
              color="marine"
              textAlign="center"
            >
              BOX No: {BOX_SIZES[size]}
            </Heading>
          </div>
          <div style={{padding:"10px", background: "#ECEDF0"}}>
            <Heading fontSize={["12px","12px","16px"]} color="blues.peacock" pb="20px">
                {_id}
            </Heading>
            <Text fontSize="12px" lineHeight="1.47" color="marine">
              {fullname}
            </Text>
            <Text fontSize="12px" lineHeight="1.47" color="marine">
              {address.street}, {address.city}
            </Text>
            <Text fontSize="12px" lineHeight="1.47" color="marine">
              {address.zipCode} GREECE
            </Text>
          </div>
          <div style={{flexGrow:1}}></div>
          <div style={{padding:"10px"}}>
              <Barcode value={_id} width="300px" height={80} bg="#ffffff" />
              <Box py="12px" 
                pr={['5px', '12px', '12px']} 
                alignSelf="flex-end"
              >
                <Heading
                  fontSize="26px"
                  lineHeight="1.09"
                  textAlign="right"
                  color="blues.peacock"
                >
                  {currency(shippingCost)}
                </Heading>
                <Text
                  pt="12px"
                  fontSize="12px"
                  lineHeight="2.3"
                  letterSpacing="0.6px"
                  textAlign="right"
                  color="marine"
                >
                 {datetime(createdAt)}
                </Text>
              </Box>
          </div>
          
        </div>
      </Card>
      </div>
    )
}

class ComponentToPrint extends React.Component {
    render() {
        const {
            props: { pendingBoxes },
            } = this;

      return (
        <div style={{fontFamily: "Verdana, sans-serif"}}>
          <div style={{
              padding:"20px",
              background: "#0B5190"
            }}>
               <img src="https://www.weboxit.com/logo-header.png" />
          </div>
          <div style={{padding:"20px"}}>
            { pendingBoxes.map((box, i) => (
                <BoxPrintItem key={i} box={box} />
            ))
            }
          </div>
          <div style={{
              padding:"20px",
              background: "#0B5190"
            }}>
               <img src="https://www.weboxit.com/logo-header.png" />
          </div>
        </div>
      );
    }
  }

const PrintAllBtn = (props)=> (<Button
  mt="58px"
  py="10px"
  px="20px"
  fontSize="14px"
  fontWeight="bold"
  letterSpacing="2.34px"
  bg="blues.peacock"
  {...props}
>
  PRINT ALL VOUCHERS NOW
</Button>)

const PrintVoucherBtn = (props)=> (<Button
  fontSize="14px"
  bg="transparent"
  color="blues.peacock"
  {...props}
>
<Image src={IconPrint} height="28px" /> Print Voucher
</Button>)

const PrintBoxes = ({pendingBoxes, printAll})=>{
    const componentRef = useRef();
    return (
      <div>
        <ReactToPrint
          trigger={() => ( printAll ? <PrintAllBtn />: <PrintVoucherBtn />)}
          content={() => componentRef.current}
          copyStyles={false}
        />
        <div style={{ display: "none" }}>
            <ComponentToPrint ref={componentRef} pendingBoxes={pendingBoxes} />
        </div>
      </div>
    );
  };


export default PrintBoxes;