import React from 'react';
import { Image, Text, Flex } from 'rebass';
import { useTranslation } from 'react-i18next';
import { useIdentityContext } from 'react-netlify-identity';
import { navigate } from 'gatsby';
import { gql } from 'apollo-boost';
import { useQuery } from '@apollo/react-hooks';
import queryString from 'query-string'

import SEO from '../components/seo';
import { Layout } from '../layout';
import { Container } from '../components/container';
import { Header } from '../components/Header';

import PrintBoxes from '../components/PrintBoxes';
import CheckCircleIcn from '../icons/check-circle.svg';


// "6f8d46f0-39b5-454b-b5c7-58fb750c2ef4"
export const FIND_BOXES_BY_MERCHANT_REF = gql`
query FindPurchaseByMerchant($merchantRef: String!) {
  findPurchaseByMerchantRef(merchantRef:$merchantRef){
    data{
      _id
      boxes{
          _id
          size
          createdAt
          shippingCost
          status
          recipient{
            fullname
            address{
              city
              street
              zipCode
            }
          }
        }
      status
    }
  }
}`


const BoxesTransactionConfirmationPanel = ({merchantRef}) => {
  console.log(merchantRef)
  const { t } = useTranslation();

  const queryVars = { 
    merchantRef: merchantRef, 
   }

  const queryRes = useQuery(FIND_BOXES_BY_MERCHANT_REF, {
      variables: queryVars
  });

  const { loading, error, data } = queryRes

  if(loading){
      return null
  }

  if(error){
      console.log(error)
      return null;
  }

  const boxes = data.findPurchaseByMerchantRef.data[0].boxes;

  return (
   <Flex flexDirection="column" alignItems="center">
      <Image pt="34px" src={CheckCircleIcn} />
      <Text pt="25px" fontSize="35px" lineHeight="0.74" color="blues.peacock">
      { t('my_boxit.boxCheckoutModal.confirmationPanelText1') }
      </Text>
      <Text
        pt="25px"
        px={["0","120px","120px"]}
        fontSize="20px"
        color="marine"
        lineHeight="1.5"
        textAlign="center"
      >
        { t('my_boxit.boxCheckoutModal.confirmationPanelText2') }
      </Text>
      <Text
        pt="35px"
        fontSize="20px"
        color="marine"
        lineHeight="1.5"
        textAlign="center"
      >
        { t('my_boxit.boxCheckoutModal.confirmationPanelText3') }
      </Text>
      <PrintBoxes pendingBoxes={boxes} printAll />
    </Flex>
  );
}

const PurchaseSuccessPage = props => {
  const { t } = useTranslation();
  const { isLoggedIn} = useIdentityContext();

  const navigateToHome = ()=>{
    if (typeof window !== `undefined`){
      navigate(`/`);
    }
  }

  if(!isLoggedIn){
    navigateToHome();
    return null;
  }

  const query = props.location.search ? queryString.parse(props.location.search): {}
  console.log(query);
  if(!query.mid){
    return null;
  }

  return (
    <Layout {...props}>
      <SEO title="PURCHASE SUCCESS" keywords={['weboxit', 'boxit']} />
      <Header
        title={t('purchase.successPage.title')}
        subtitle={t('purchase.successPage.subtitle')}
      />
      <Container>
        <BoxesTransactionConfirmationPanel merchantRef={query.mid} />
      </Container>
    </Layout>
  );
};

export default PurchaseSuccessPage;
